export const siteDescription =
  "Förderverein Blindenskisport – Lappersdorf e.V.";
export const siteTitle = "blind.ski";

export const accentColor = "#1d74f5";
export const accentColorLight = "#5694f0";
export const mainBackgroundColor = "#000014";

export const triangleSize = "6rem";
export const hourSize = 5.1;

export const transitionDuration = "0.2s";

export const breakpointTablet = "1024px";
export const breakpointMobile = "766px";
