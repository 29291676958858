import styled from "@emotion/styled";
import * as React from "react";
import { accentColor } from "../constants";
import { Container } from "./container";
import { Column } from "./grid/column";
import { Row } from "./grid/row";
import { Image } from "./image";
import { Link } from "./link";

const FooterContainer = styled.div`
  padding: 1rem;
  color: #000;
  background-color: white;
`;

const List = styled.ul`
  list-style: none;
  margin: 0rem;
  padding: 0rem;
`;

const Item = styled.li`
  display: inline-block;

  & + &:before {
    content: "\\000B7";
    display: inline-block;
    padding: 0 0.3rem;
  }
`;

const RightAligned = styled.div`
  text-align: right;
`;

const AccentText = styled.span`
  color: #ff0000;
`;

export const Footer = () => (
  <FooterContainer>
    <Container>


      <Row>
        <Column grow={1}>
          <List>
            <Item>
              <Link
                label="Probleme mit der Website?"
                target="_blank"
                to="mailto:webmaster@blind.ski"
              />
            </Item>

            <Item>
              <Link label="Impressum" to="imprint" />
            </Item>
            <Item>
              <Link label="Datenschutz" to="imprint/#data" />
            </Item>
            <Item>
              <Link
                label="Vereinssatzung"
                target="_blank"
                to="https://blindenskisport.sharepoint.com/:w:/s/agit/EbHVlPh87OlNhP4Sy9t-x0IBdodVHDfZtgaz1iZ4bbYUlg?e=ykytM4"
              />
            </Item>
          </List>
        </Column>

        <Column grow={1}>
          <RightAligned>
            Erstellt mit <AccentText>&hearts;</AccentText> in Lappersdorf
          </RightAligned>
        </Column>
        <p> </p>
      </Row>
      <p> </p>
    </Container>
  </FooterContainer>
);
